<template>
    <v-container v-if="$store.state.user.components.includes(83)">
        <v-card>
            <v-card-text>
                <crud model="InsuranceCompany" :headers="headers" label="Insurance Companies" :conditions="[{deleted:{is:null}}]"></crud>
            </v-card-text>
        </v-card>
    </v-container>
</template>
<script>
import crud from "../../components/CRUD.vue";
export default {
    components: {
        crud
    },
    data() {
        return {
            headers: [
                {value: "id", text: "ID", visible: false, editable: false, isId: true},
                {value: "name", text: "Name", mandatory: true, searchable: true},
                {value: "alias", text: "Alias", searchable: true},
                {value: "PID", text: "Payer ID", searchable: true },
                {value: "EID", text: "Eligibility ID", searchable: true },
                {value: "type", text: "Type", type: "select", items: ["Payer", "Self Pay", "Client Pay", "Collector"], mandatory: true},
                {value: "state", text: "State", type: "select", items: this.$store.state.usStatesAbrv, searchable:true},
                {value: "status", text: "Status", type: "select", items: ["Active", "Inactive", "Pending"], mandatory: true}
            ]
        };
    }
};
</script>